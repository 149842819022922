.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.itemDate {
  font-size: 10px
}

.itemDescription {
  font-size: 16px;
  display: inline-block;
  width: 300px;

}

ul {
  padding-left: 10px;
  width: 400px;
}

li {
  padding-left: 5px;
  padding-top: 2px;
  padding-right: 2px;
  background-color: #c1c4cc;
  border-bottom: 1px solid #f0f0f0;

  vertical-align: top;
}

.modal {
  z-index: 2;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #c1c4cc;
  padding: 12px 12px;
  border-style: solid;
}

.modalBtn {
  z-index: -1;
  padding: 6px 6px;
}

.modalTxt {
  /*padding: 6px 6px;*/
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  margin: 0px;
  padding: 2px;
  width: 500px;
}

.modalTxtArea {
  font-size: medium;
  font-family: Arial, Helvetica, sans-serif;
  width: 506px;
  height: 150px;
  margin: 0px;
  padding: 0px;
}


.modalLbl {
  padding: 6px 6px;
}

.icon {
  font-size: 2rem;
  padding: 0.5rem;
  border-radius: 4px;
  background-color: var(--gray-1);
  color: var(--black-2);
  display: flex;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  cursor: pointer;
  transition: 0.3s ease background-color;
  display: inline-block;


  &:hover {
    background-color: var(--gray-2);
  }
}


.todoActions {
  display: flex;
  align-items: right;
  justify-content: right;
  gap: 1rem;
  display: inline-block;
}

.inputCell {
  display: block
}


.container {
  display: flex;
  flex-direction: column;
  background-color: #c1c4cc;
  width: fit-content;
}

.row {
  display: flex;
  justify-content: space-between;
  border: 1px solid #ccc;
  padding: 0 0 5px;
}

.column {
  flex: 1;
  text-align: left;

  border: 1px solid #ccc;

}


.containerModal {
  display: flex;
  flex-direction: column;
  z-index: 2;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ecedf0;
  padding: 12px 12px;
  border-style: solid;
  text-align: left;
}



#formStyle h1 {
  margin-top: 0;
}



#formStyle form {
  margin: 0 auto;
  width: 400px;
  padding: 1em;
  border: 1px solid #ccc;
  border-radius: 1em;
}

#formStyle div+div {
  margin-top: 1em;
}

#formStyle label span {
  display: inline-block;
  text-align: right;
}

#formStyle input,
textarea {
  font: 1em sans-serif;
  width: 350px;
  box-sizing: border-box;
  border: 1px solid #999;
}

#formStyle textarea {
  height: 100px;
}

#formStyle input[type="checkbox"],
#formStyle input[type="radio"] {
  width: auto;
  border: none;
}

#formStyle input:focus,
textarea:focus {
  border-color: #000;
}

#formStyle>textarea {
  vertical-align: top;
  height: 5em;
  resize: vertical;
}

#formStyle fieldset {
  width: 250px;
  box-sizing: border-box;
  border: 1px solid #999;
}

#formStyle button {
  margin: 20px 0 0 0;
}

#formStyle label {
  position: relative;
  display: inline-block;
}

#formStyle p label {
  width: 100%;
}

#formStyle label em {
  position: absolute;
  right: 5px;
  top: 20px;
}