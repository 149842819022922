table {
    border-collapse: collapse;
    width: 100%;

}

th,
td {
    text-align: left;
    padding: 12px;
    font-size: small;

}



tr:nth-child(even) {
    background-color: #ecedf0;
}

/*
tr:nth-child(even):hover {
    background-color: #5a5b5e;
}

tr:nth-child(odd):hover {
    background-color: #5a5b5e;
}
*/

thead tr {
    background-color: #332d2d;
}

thead td {
    font-size: medium;
}

thead a {
    color: whitesmoke;
    text-decoration: none;
}

#dynamicTable button {
    background-color: transparent;
    color: white;
    background-repeat: no-repeat;
    border: none;
    cursor: pointer;
    overflow: hidden;
    outline: none;
}