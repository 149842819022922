#bar-chart-1 {
    width: 30%;
    
    margin: 1 auto;
}

@media only screen and (max-width: 600px) {
    #bar-chart-1 {
        max-width: 600px
    }
}


#bar-chart-1 .column {
    --aspect-ratio: 6 / 3;
}



#bar-chart-1 table caption {
    margin-bottom: 15px;
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-weight: bold;
    font-size: medium;

}

#bar-chart-1 .tooltip {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 12px;
}

#bar-chart-1 td span {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: smaller;
}

#bar-chart-1 th {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: small;
}

/*Rising Bars*/
#bar-chart-1 .column tbody {
    overflow-y: hidden;
    /* remove this to see how it works */
}

#bar-chart-1 .column tbody th {
    background-color: #fff;
    z-index: 1;
}

#bar-chart-1 .data {
    margin-top: 4px;
}

#bar-chart-1 .column tbody td {
    animation: moving-bars 2s linear 1;
    margin-inline-start: 5px;
}

@keyframes moving-bars {
    0% {
        transform: translateY(100%);
    }

    30% {
        transform: translateY(0);
    }
}